/*
*   File : retirement.js
*   Author : https://evoqins.com
*   Description : Screen to show goal Investment for retirement
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, Loader, StepProgress } from "../../Components/Other";
import { CustomAmountInput, EditableSlider, RadioGroup } from "../../Components/FormElements";
import { PrimaryButton } from "../../Components/Buttons";
import { GoalInformation, GoalInvest } from "./";
import { RiskKycConfirmationModal } from "../../Components/Modal";

// Services, helper
import APIService from "../../Services/api-service";
import { _getGoal } from "../../Helper/api";

const RADIO_OPTION = [
    {
        name: "Yes",
        id: 1
    },
    {
        name: "No",
        id: 2
    }
];

const RetirementGoal = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const annualRef = useRef();
    const existingInvestmentRef = useRef();

    const [goalDetail, setGoalDetail] = useState({});
    const [currentAge, setCurrentAge] = useState(0);
    const [retirementAge, setRetirementAge] = useState(0);
    const [inflation, setInflation] = useState(0);
    const [existingInvestment, setExistingInvestment] = useState(null);
    const [existingInvestmentError, setExistingInvestmentError] = useState(null);
    const [selectedInvestment, setSelectedInvestment] = useState(RADIO_OPTION[1].id);
    const [configurationStep, setConfigurationStep] = useState(1);

    const [monthlyExpense, setMonthlyExpense] = useState("");
    const [monthlyExpenseError, setMonthlyExpenseError] = useState("");
    const [lifeExpentency, setLifeExpentency] = useState(34);
    const [returnPostRetirement, setReturnPostRetirement] = useState(10);
    const [screenLoader, setScreenLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);
    const [expectedReturn, setExpectedReturn] = useState(10);
    const [locationData, setLocationData] = useState(null);
    const [riskKycCheck, setRiskKycCheck] = useState(false);

    useEffect(() => {
        if (location.state !== null) {
            _goalDetail(location.state.goal_id);
            setConfigurationStep(location.state.step);
            if (location.state.step === 3) {
                setLocationData(location.state);
            }
        }
    }, [location]);

    useEffect(() => {
        if (riskKycCheck === true) {
            const modal = new Modal(document.getElementById("risk-kyc-check"));
            modal.show();
        }
    }, [riskKycCheck]);

    // navigate to home screen
    function _handleParentNavigate() {
        navigate("/");
    }

    // navigate to parent screen
    function _handleCurrentNavigate() {
        navigate("/milestone");
    }

    // handle current age
    function _handleCurrentAge(slider_value) {
        setCurrentAge(slider_value);
    }

    // handle retirement age
    function _handleRetirementAge(slider_value) {
        setRetirementAge(slider_value);
    }

    // handle inflation
    function _handleInflation(slider_value) {
        setInflation(slider_value);
    }

    // handle investment
    function _handleInvestment(radio) {
        setSelectedInvestment(radio);
        if (radio === 2) {
            setExistingInvestment(null);
        }
    }

    // handle existingInvestment
    function _handleAmount(input_value) {
        setExistingInvestment(input_value);
        setExistingInvestmentError("");
    }

    // handle expected return
    function _handleExpectedReturn(slider_value) {
        setExpectedReturn(slider_value);
    }

    // _handle monthly expense
    function _handleMonthlyExpense(value) {
        setMonthlyExpense(value);
        setMonthlyExpenseError("");
    }

    // handle life expentency
    function _handleLifeExpentency(value) {
        setLifeExpentency(value);
    }

    function _handleReturnPostRetirement(value) {
        setReturnPostRetirement(value)
    }

    function _confirmRiskKyc(status) {
        setRiskKycCheck(status)
    }

    function _handleNavigateToScreen() {
        navigate("/risk-profile")
    }

    // handle submit 1st step
    function _submitRetirement() {
        let valid = true;
        let firstErrorRef = null;
        toast.dismiss();
        if (monthlyExpense === "") {
            setMonthlyExpenseError("Enter the monthly expenses");
            if (!firstErrorRef) {
                firstErrorRef = annualRef;
            }
            valid = false;
        }
        if (selectedInvestment === 1 && (existingInvestment === null || existingInvestment === "" || existingInvestment === 0)) {
            if (!firstErrorRef) {
                firstErrorRef = existingInvestmentRef;
            }
            setExistingInvestmentError("Enter the invested amount");
            valid = false;
        }

        if (lifeExpentency <= retirementAge) {
            toast.error("Life expectancy should be greater than retirement age", {
                type: "error",
            });
            valid = false;
        } else if (goalDetail.admin_configured.meta_data.current_age.min_age > currentAge) {
            toast.error("Current age should not be less than minimum age", {
                type: "error",
            });
            valid = false;
        } else if (goalDetail.admin_configured.meta_data.retiring_age.min_age > retirementAge) {
            toast.error("Retirement age should be greater than retirement minimum age", {
                type: "error",
            });
            valid = false;
        } else if (currentAge >= retirementAge) {
            toast.error("Retirement age should be greater than current age", {
                type: "error",
            });
            valid = false;
        } else if (returnPostRetirement < goalDetail.admin_configured.meta_data.return_after_retirement.min) {
            toast.error(`Returns post retirement should be greater than or equal to ${goalDetail.admin_configured.meta_data.return_after_retirement.min} %`, {
                type: "error",
            });
            valid = false;
        } else if (goalDetail.admin_configured.meta_data.life_expectancy.min_age > lifeExpentency) {
            toast.error("Life expectancy year should not be less than minimum life expectancy year", {
                type: "error",
            });
            valid = false;
        }

        if (firstErrorRef) {
            firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            firstErrorRef.current.querySelector('input').focus();
        }

        if (valid === true) {
            setApiLoader(true);
            _setRetirement();
        }
    }


    // API - get goal detail
    const _goalDetail = (id) => {
        _getGoal(id)
            .then((response) => {
                if (response.is_configured === false) {
                    setCurrentAge(response.admin_configured.meta_data.current_age.default);
                    setRetirementAge(response.admin_configured.meta_data.retiring_age.default);
                    setInflation(response.admin_configured.meta_data.inflation_rate.default);
                    setLifeExpentency(response.admin_configured.meta_data.life_expectancy.default);
                    setMonthlyExpense(response.admin_configured.meta_data.monthly_expenses.default);
                    setLocationData(null);
                    setExpectedReturn(response.admin_configured.meta_data.return_after_retirement.default)
                } else {
                    setCurrentAge(response.user_configured.additional_data.current_age);
                    setRetirementAge(response.user_configured.additional_data.retirement_age);
                    setInflation(response.user_configured.inflation_rate);
                    setMonthlyExpense(response.user_configured.additional_data.monthly_expenses);
                    setExistingInvestment(response.user_configured.additional_data.existing_investment > 0 ? response.user_configured.additional_data.existing_investment : null);
                    if (response.user_configured.additional_data.existing_investment > 0) {
                        setSelectedInvestment(1);
                    }
                    setLifeExpentency(response.user_configured.additional_data.life_expectancy);
                    if (response.user_configured.additional_data.pre_expected_return) {
                        setExpectedReturn(response.user_configured.additional_data.pre_expected_return)
                    }
                    if (response.user_configured.additional_data.expected_return) {
                        setReturnPostRetirement(response.user_configured.additional_data.expected_return)
                    }
                }


                setGoalDetail(response);
                setScreenLoader(false);
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    }

    // API - set retirement
    const _setRetirement = () => {
        const url = "/goal/calculate-corpus";
        const request = {
            goal_id: location.state.goal_id,
            current_age: currentAge,
            retirement_age: retirementAge,
            monthly_expenses: monthlyExpense,
            existing_investment: existingInvestment === null ? 0 : existingInvestment,
            life_expectancy: lifeExpentency,
            inflation: inflation,
            expected_return: returnPostRetirement,
            pre_expected_return: expectedReturn,
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setConfigurationStep(2);
                navigate(location.pathname, {
                    state: {
                        goal_id: location.state.goal_id,
                        step: 2,
                        request_data: response.data
                    }
                });

            } else {
                if (response.data && response.data.risk_not_done === true) {
                    _confirmRiskKyc(true);
                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        type: "error",
                    });
                }
            }

            setApiLoader(false);
        })
    }

    // handle to go back previous step
    function _handleConfigurationStep(step) {
        navigate(location.pathname, {
            state: {
                goal_id: location.state.goal_id,
                step: step,
                request_data: location.state.request_data
            }
        })
    }

    return (
        <>
            {
                screenLoader === true ?
                    <Loader height="h-80vh" />
                    :
                    <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                        <div className="row ">
                            <div className="col-12 padding-144px-bottom">
                                {/* Header */}
                                <Header type={2} />
                                <div className="px-4 ">
                                    <Breadcrumb mainPage="Home"
                                        currentPage="Milestones"
                                        childPage={goalDetail.admin_configured.name}
                                        handleParentNavigate={_handleParentNavigate}
                                        handleCurrentNavigate={_handleCurrentNavigate}
                                        type={2} />

                                    <div className="row pt-4 d-flex justify-content-center">
                                        <div className="col-lg-6">
                                            {/* Step progress */}
                                            <StepProgress stepCompleted={configurationStep}
                                                gotToStep={_handleConfigurationStep} />
                                        </div>
                                    </div>


                                    {
                                        configurationStep === 1 ?
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-lg-6">
                                                    <div className="mt-4 e-goal-bg border-radius-16px d-flex align-items-center p-3">
                                                        <img src={goalDetail.admin_configured.image}
                                                            alt="goals"
                                                            width={65}
                                                            height={54} />
                                                        <div className="padding-12px-left">
                                                            <h6 className="mb-1 color-eerie-black e-font-16 e-line-height-22 e-montserrat-semi-bold">{goalDetail.admin_configured.name}</h6>
                                                            <p className="mb-0 color-dim-gray e-font-14 e-line-height-20 e-montserrat-medium">{goalDetail.admin_configured.description}</p>
                                                        </div>
                                                    </div>

                                                    <EditableSlider label="How old are you now?"
                                                        value={currentAge}
                                                        handleChange={_handleCurrentAge}
                                                        edit={true}
                                                        right={true}
                                                        min={goalDetail.admin_configured.meta_data.current_age.min_age}
                                                        max={goalDetail.admin_configured.meta_data.current_age.max_age}
                                                        step={goalDetail.admin_configured.meta_data.current_age.step}
                                                        className="mt-4"
                                                        suffix={currentAge === 1 ? "Yr" : "Yrs"}
                                                        singularSuffix={goalDetail.admin_configured.meta_data.current_age.min_age === 1 ? "Yr" : "Yrs"}
                                                        pluralSuffix="Yrs" />

                                                    <EditableSlider label="When do you want to retire?"
                                                        value={retirementAge}
                                                        handleChange={_handleRetirementAge}
                                                        edit={true}
                                                        right={true}
                                                        min={goalDetail.admin_configured.meta_data.retiring_age.min_age}
                                                        max={goalDetail.admin_configured.meta_data.retiring_age.max_age}
                                                        step={goalDetail.admin_configured.meta_data.retiring_age.step}
                                                        className="mt-4"
                                                        suffix={retirementAge === 1 ? "Yr" : "Yrs"}
                                                        singularSuffix={goalDetail.admin_configured.meta_data.retiring_age.min_age === 1 ? "Yr" : "Yrs"}
                                                        pluralSuffix="Yrs" />

                                                    <div ref={annualRef}>
                                                        <CustomAmountInput value={monthlyExpense}
                                                            error={monthlyExpenseError}
                                                            postfix="*"
                                                            className="margin-28px-top"
                                                            label="What is your monthly expense"
                                                            placeholder="8,00,000"
                                                            handleChange={_handleMonthlyExpense} />
                                                    </div>

                                                    <EditableSlider label="What is the expected inflation rate to be considered?"
                                                        value={inflation}
                                                        handleChange={_handleInflation}
                                                        min={goalDetail.admin_configured.meta_data.inflation_rate.min}
                                                        max={goalDetail.admin_configured.meta_data.inflation_rate.max}
                                                        step={goalDetail.admin_configured.meta_data.inflation_rate.step}
                                                        className="mt-4"
                                                        suffix="%"
                                                        singularSuffix="%"
                                                        pluralSuffix="%" />

                                                    <div className="mt-4">
                                                        <p className="mb-md-3 mb-0 color-dark-charcoal e-font-14 e-line-height-24 e-montserrat-medium">Have you started saving for it?</p>
                                                        <RadioGroup data={RADIO_OPTION}
                                                            selected={selectedInvestment}
                                                            handleSelect={_handleInvestment} />
                                                    </div>
                                                    {
                                                        selectedInvestment === 1 &&
                                                        <Fragment>
                                                            <div className="mt-md-4 mt-3 pb-1" ref={existingInvestmentRef}>
                                                                <CustomAmountInput
                                                                    value={existingInvestment}
                                                                    error={existingInvestmentError}
                                                                    postfix="*"
                                                                    label="How much you have currently saved for this purpose?"
                                                                    placeholder="5,00,000"
                                                                    handleChange={_handleAmount} />
                                                            </div>
                                                            <EditableSlider label="What returns are you currently getting on this investment?"
                                                                value={expectedReturn}
                                                                handleChange={_handleExpectedReturn}
                                                                min={goalDetail.admin_configured.meta_data.pre_expected_return.min}
                                                                max={goalDetail.admin_configured.meta_data.pre_expected_return.max}
                                                                step={goalDetail.admin_configured.meta_data.pre_expected_return.step}
                                                                className="mt-4"
                                                                suffix="%"
                                                                singularSuffix="%"
                                                                pluralSuffix="%" />
                                                        </Fragment>
                                                    }


                                                    <EditableSlider label="What is your expected return post retirement?"
                                                        value={returnPostRetirement}
                                                        handleChange={_handleReturnPostRetirement}
                                                        edit={true}
                                                        right={true}
                                                        min={goalDetail.admin_configured.meta_data.return_after_retirement.min}
                                                        max={goalDetail.admin_configured.meta_data.return_after_retirement.max}
                                                        step={goalDetail.admin_configured.meta_data.return_after_retirement.step}
                                                        className="mt-md-4 mt-2"
                                                        suffix={"%"}
                                                        singularSuffix="%"
                                                        pluralSuffix="%" />

                                                    <EditableSlider label="Life Expectancy?"
                                                        value={lifeExpentency}
                                                        handleChange={_handleLifeExpentency}
                                                        edit={true}
                                                        right={true}
                                                        min={goalDetail.admin_configured.meta_data.life_expectancy.min_age}
                                                        max={goalDetail.admin_configured.meta_data.life_expectancy.max_age}
                                                        step={goalDetail.admin_configured.meta_data.life_expectancy.step}
                                                        className="mt-md-4 mt-2"
                                                        suffix={lifeExpentency === 1 ? "Yr" : "Yrs"}
                                                        singularSuffix={goalDetail.admin_configured.meta_data.life_expectancy.min_age === 1 ? "Yr" : "Yrs"}
                                                        pluralSuffix="Yrs" />

                                                    <ul className="padding-12px-tb mt-2 e-bg-light-blue border-radius-12px mt-4 color-ocean-blue e-font-14 e-line-height-20 gap-8px e-montserrat-medium">
                                                        <li className="mb-1">As per studies Indian average life expectancy is 85 years.</li>
                                                        <li>Pre-retirement returns for this calculation are derived from the average historical returns of the funds shown in the next step.</li>
                                                    </ul>
                                                    {/* <div className="d-flex align-items-start padding-12px-all mt-2 e-bg-light-blue border-radius-12px mt-4 color-ocean-blue e-font-14 e-line-height-20 gap-8px e-montserrat-medium">
                                                    </div> */}
                                                    <div className="mt-5 border-top-1px border-bright-gray mb-4"></div>
                                                    <div className="d-flex justify-content-center">
                                                        <div className="col-lg-6 col-md-4 col-6">
                                                            <PrimaryButton label="Continue"
                                                                showLoader={apiLoader}
                                                                disabled={apiLoader}
                                                                className="padding-10px-tb w-100"
                                                                onPress={_submitRetirement} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            : configurationStep === 2 ?
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-lg-6">
                                                        <GoalInformation text1="To make your dream of secured retirement in"
                                                            locationData={locationData}
                                                            text2="a reality, you'll need a corpus of" />
                                                    </div>
                                                </div>
                                                :
                                                <div className="row ">
                                                    <div className="col-xl-10 col-lg-11">
                                                        <GoalInvest />
                                                    </div>
                                                </div>
                                    }


                                </div>

                            </div>
                        </div>
                    </div>
            }
            {
                riskKycCheck === true && <RiskKycConfirmationModal
                    title="Complete Risk Profile"
                    content="Please complete your Risk Profile to start with an Investment."
                    closeModal={() => _confirmRiskKyc(false)}
                    handleConfirm={_handleNavigateToScreen} />
            }
        </>
    )
}

export default RetirementGoal;